import React, { useState, useEffect } from 'react';
import { useContext } from 'react';
import { CurrentUserContext } from '../contexts/CurrentUserContext';
import { digitalOptionCost, smallPrintCost, bigPrintCost } from '../utils/prices';
//import { api } from '../utils/api';

export default function Card({
  card,
  onDownloadOption,
  onYearbookOption,
  onImageClick,
  onHandleConfirmDeleteCard,
  onSmallPrintClickUp,
  onSmallPrintClickDown,
  onBigPrintClickUp,
  onBigPrintClickDown,
}) {
  const [ownerData, setOwnerData] = useState({ name: '', about: '' });

  useEffect(() => {
    // Function to fetch user data based on card owner
    const fetchOwnerData = async () => {
      try {
        const response = await fetch(`https://api.myphotodelight.ru/users/${card.owner}`, {
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage.getItem('jwt')}`
          }
        });
        if (!response.ok) {
          throw new Error(`Failed to fetch user data. Status: ${response.status}`);
        }
        const userData = await response.json();
        setOwnerData({ name: userData.name, about: userData.about });
      } catch (error) {
        console.error('Error fetching owner data:', error);
      }
    };
  
    fetchOwnerData(); // Call the function when component mounts
  }, [card.owner]); // Fetch again if card owner changes

  const handleImageClick = () => onImageClick(card);
  const handleConfirmDeleteCard = () => onHandleConfirmDeleteCard(card);
  const handleDownloadOptionClick = () => onDownloadOption(card);
  const handleYearbookOptionClick = () => onYearbookOption(card);
  const handleSmallPrintClickUp = () => onSmallPrintClickUp(card);
  const handleSmallPrintClickDown = () => onSmallPrintClickDown(card);
  const handleBigPrintClickUp = () => onBigPrintClickUp(card);
  const handleBigPrintClickDown = () => onBigPrintClickDown(card);

  const currentUser = useContext(CurrentUserContext);
  const isOwn = card.owner === currentUser._id
  const isAdmin = currentUser._id === '661e109065da78076ad58fbc';

  const isDownloadOption = card.downloadOption.some((i) => i === currentUser._id);
  const isYearbookOption = card.yearbookOption.some((i) => i === currentUser._id);

  const downloadOptionButtonClass = (`element__heart-btn ${isDownloadOption && 'element__heart-btn_active'}`);
  const yearbookOptionButtonClass = (`element__heart-btn ${isYearbookOption && 'element__heart-btn_active'}`);

  return (
    <CurrentUserContext.Provider value={currentUser}>
      {(isOwn || isAdmin) && (
        <article className='element'>
          {(isOwn || isAdmin) && (<button type='button' className='element__trash-btn' onClick={handleConfirmDeleteCard} />)}

          <img className='element__image' alt={ownerData.name} src={card.link} onClick={handleImageClick} />

          <div className='element__options'>
            {isAdmin && (
              <>
                <h2 className='element__title'>{ownerData.name}</h2>
                <h2 className='element__title'>{ownerData.about}</h2>
                <h2 className='element__title'>{card.name}</h2>
              </>
            )}
            {!isAdmin && (
              <>
                <h2 className='element__title'>{isOwn ? card.name : ownerData.name}</h2>
                {!card.isClassPhoto ? (
                  <>
                    <div className='element__option'>
                      Use for Yearbook (free): <button type='button' className={yearbookOptionButtonClass} onClick={handleYearbookOptionClick} />
                    </div>

                    <div className='element__option'>
                      Download ({digitalOptionCost} RUB): <button type='button' className={downloadOptionButtonClass} onClick={handleDownloadOptionClick} />
                    </div>

                    <div className='element__option'>
                      Print 10×15 ({smallPrintCost} RUB):
                      <div className='element__print-counter-group'>
                        <button
                          type='button'
                          className='element__print-down'
                          onClick={handleSmallPrintClickDown}
                          disabled={card.smallPrintCounter === 0}>
                        </button>
                        <span className='element__print-counter'>{!card.smallPrintCounter ? 0 : card.smallPrintCounter}</span>
                        <button
                          type='button'
                          className='element__print-up'
                          onClick={handleSmallPrintClickUp}
                          disabled={card.smallPrintCounter === 10}
                        ></button>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className='element__option'>
                    Class photo is available as a large format print only
                  </div>
                )}

                <div className='element__option'>
                  Print 20×30 ({bigPrintCost} RUB):
                  <div className='element__print-counter-group'>
                    <button
                      type='button'
                      className='element__print-down'
                      onClick={handleBigPrintClickDown}
                      disabled={card.bigPrintCounter === 0}>
                    </button>
                    <span className='element__print-counter'>{!card.bigPrintCounter ? 0 : card.bigPrintCounter}</span>
                    <button
                      type='button'
                      className='element__print-up'
                      onClick={handleBigPrintClickUp}
                      disabled={card.bigPrintCounter === 10}
                    ></button>
                  </div>
                </div>
              </>
            )}
          </div>
        </article>
      )}
    </CurrentUserContext.Provider>
  );
}

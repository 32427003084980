import React from 'react'
import { useContext } from 'react';
import Card from '../components/Card';
import { CurrentUserContext } from '../contexts/CurrentUserContext';

export default function Main({
  onAvatarEdit,
  onProfileEdit,
  onAdd,
  onBulk,
  onImageClick,
  onHandleConfirmDeleteCard,
  onDownloadOption,
  onYearbookOption,
  onSmallPrintClickUp,
  onSmallPrintClickDown,
  onBigPrintClickUp,
  onBigPrintClickDown,
  cards,
  editButtonsVisible,
}) {

  const currentUser = useContext(CurrentUserContext);

  return (
    <main className='main'>
      <section className='profile'>
        <div onClick={onAvatarEdit} className='profile__avatar'>
          <img src={currentUser.avatar} className='profile__image' alt={currentUser.name} />
        </div>
        <div className='profile__info'>
          <div className='profile__wrapper'>
            <h1 className='profile__title'>{currentUser.name}</h1>
            <button onClick={onProfileEdit} type='button' className='profile__edit-btn' />
          </div>
          <h2 className='profile__occupation'>{currentUser.about}</h2>
        </div>
        {/* {editButtonsVisible && (<button onClick={onAdd} type='button' className='profile__add-btn'></button>)} */}
        {editButtonsVisible && (<button onClick={onBulk} type='button' className='profile__add-btn'></button>)}

      </section>
      <section className='elements'>
        {
          cards.map((card) => (
            <Card
              key={card._id}
              card={card}
              onImageClick={onImageClick}
              onHandleConfirmDeleteCard={onHandleConfirmDeleteCard}
              onSmallPrintClickUp={onSmallPrintClickUp}
              onSmallPrintClickDown={onSmallPrintClickDown}
              onBigPrintClickUp={onBigPrintClickUp}
              onBigPrintClickDown={onBigPrintClickDown}
              onDownloadOption={onDownloadOption}
              onYearbookOption={onYearbookOption}
            />
          ))
        }
      </section>
    </main>
  )
}

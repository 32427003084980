import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';

import * as authentication from '../utils/auth';
import { api } from '../utils/api';
import { CurrentUserContext } from '../contexts/CurrentUserContext';

import Main from './Main';
import Header from './Header';
import Footer from './Footer';
import Login from './Login';
import Register from './Register';
import ProtectedRoute from './ProtectedRoute';

import ImagePopup from './ImagePopup';
import ConfirmationPopup from './ConfirmationPopup';
import AddPlacePopup from './AddPlacePopup';
import AddBulkPopup from './AddBulkPopup';
import EditAvatarPopup from './EditAvatarPopup';
import EditProfilePopup from './EditProfilePopup';
import InfoToolTip from './InfoToolTip';
import CheckoutPopup from './CheckoutPopup';
import Account from './Account/Account'
import Preloader from './Preloader/Preloader';


import Developer from './Developer/Developer';

export default function App() {
  const [currentUser, setCurrentUser] = useState({});
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = React.useState({});
  const [email, setEmail] = useState('');
  const [loggedIn, setLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isAvatarPopupOpen, setIsAvatarPopupOpen] = React.useState(false);
  const [isProfilePopupOpen, setIsProfilePopupOpen] = React.useState(false);
  const [isPlacePopupOpen, setIsPlacePopupOpen] = React.useState(false);
  const [isBulkPopupOpen, setIsBulkPopupOpen] = React.useState(false)
  const [isImagePopupOpen, setIsImagePopupOpen] = React.useState(false);
  const [isConfirmationPupupOpen, setIsConfirmationPopupOpen] = React.useState(false);
  const [isCheckoutPopupOpen, setIsCheckoutPopupOpen] = useState(false);
  const [isInfoToolTipPopupOpen, setIsInfoToolTipPopupOpen] = useState(false);

  const [toolTipTitle, setToolTipTitle] = useState('');
  const [toolTipPicture, setToolTipPicture] = useState('');
  const [editButtonsVisible, setEditButtonsVisible] = React.useState(false)

  //Print counters
  const [smallPrintCounterSum, setSmallPrintCounterSum] = React.useState(0);
  const [bigPrintCounterSum, setBigPrintCounterSum] = React.useState(0);
  const [digitalOptionCounterSum, setDigitalOptionCounterSum] = React.useState(0);
  const [yearbookOptionCounterSum, setYearbookOptionCounterSum] = React.useState(0);

  const navigate = useNavigate();

  // useEffect(() => {
  //   if (loggedIn) {
  //     Promise.all([api.getUserInfo(), api.getServerCards()])
  //       .then(([user, card]) => {
  //         setCurrentUser(user);
  //         setCards(card);
  //       })
  //       .catch((err) => window.alert(err))
  //   }
  // }, [loggedIn]);

  useEffect(() => {
    if (loggedIn) {
      Promise.all([api.getUserInfo(), api.getServerCards()])
        .then(([user, card]) => {
          setCurrentUser(user);
          // Sort cards alphabetically by name before setting the state
          setCards(card.sort((a, b) => a.name.localeCompare(b.name)));
        })
        .catch((err) => window.alert(err));
    }
  }, [loggedIn]);

  useEffect(() => {
    let smallTotal = 0;
    let bigTotal = 0;
    let digitalTotal = 0;
    let yearbookTotal = 0;

    cards.forEach(card => {
      if (card.owner === currentUser._id) {
        smallTotal += card.smallPrintCounter;
        bigTotal += card.bigPrintCounter;
        digitalTotal += card.downloadOption.length ? 1 : 0;
        yearbookTotal += card.yearbookOption.length ? 1 : 0;
      }
    });

    setSmallPrintCounterSum(smallTotal);
    setBigPrintCounterSum(bigTotal);
    setDigitalOptionCounterSum(digitalTotal);
    setYearbookOptionCounterSum(yearbookTotal);
  }, [cards, currentUser]);

  function handleAddClick() {
    setIsPlacePopupOpen(true);
  }

  function handleBulkClick() {
    setIsBulkPopupOpen(true);
  }

  function handleProfileClick() {
    setIsProfilePopupOpen(true);
  }

  function handleCheckoutClick() {
    setIsCheckoutPopupOpen(true);
  }

  function handleAvatarClick() {
    setIsAvatarPopupOpen(true);
  }

  function handleImageClick(card) {
    setSelectedCard(card);
    setIsImagePopupOpen(true);
  }

  function handleConfirmDeleteCard(card) {
    setSelectedCard(card);
    setIsConfirmationPopupOpen(true);
  }

  function closeAllPopups() {
    setIsAvatarPopupOpen(false);
    setIsProfilePopupOpen(false);
    setIsPlacePopupOpen(false);
    setIsImagePopupOpen(false);
    setIsInfoToolTipPopupOpen(false);
    setIsConfirmationPopupOpen(false);
    setIsCheckoutPopupOpen(false);
    setIsBulkPopupOpen(false);
  }

  function handleDownloadOptionCard(card) {
    const isDownloadOption = card.downloadOption.some(i => i === currentUser._id);
    api.changeDownloadOptionCardState(card._id, isDownloadOption)
      .then((newCard) => {
        setCards((state) =>
          state.map((c) => c._id === card._id ? newCard : c));
      })
      .catch((err) => window.alert(err));
  }

  function handleYearbookOptionCard(card) {
    const isYearbookOption = card.yearbookOption.some(i => i === currentUser._id);
    api.changeYearbookOptionCardState(card._id, isYearbookOption)
      .then((newCard) => {
        setCards((state) =>
          state.map((c) => c._id === card._id ? newCard : c));
      })
      .catch((err) => window.alert(err));
  }

  function handleSmallPrintClickUp(card) {
    api.setSmallPrintCounterUp(card._id)
      .then((newCard) => {
        setCards((state) =>
          state.map((c) => c._id === card._id ? newCard : c));
      })
      .catch((err) => window.alert(err));
  }

  function handleSmallPrintClickDown(card) {
    api.setSmallPrintCounterDown(card._id)
      .then((newCard) => {
        setCards((state) =>
          state.map((c) => c._id === card._id ? newCard : c));
      })
      .catch((err) => window.alert(err));
  }

  function handleBigPrintClickUp(card) {
    api.setBigPrintCounterUp(card._id)
      .then((newCard) => {
        setCards((state) =>
          state.map((c) => c._id === card._id ? newCard : c));
      })
      .catch((err) => window.alert(err));
  }

  function handleBigPrintClickDown(card) {
    api.setBigPrintCounterDown(card._id)
      .then((newCard) => {
        setCards((state) =>
          state.map((c) => c._id === card._id ? newCard : c));
      })
      .catch((err) => window.alert(err));
  }

  function handleDeleteCard(card) {
    api.deleteCard(card._id)
      .then(() => {
        setCards(state => state.filter((s) => s._id !== card._id));
      })
      .catch((err) => window.alert(err));
  }

  function handleUserUpdate(value) {
    api.setUserInfo(value)
      .then((res) => {
        setCurrentUser(res);
        closeAllPopups();
      })
      .catch((err) => window.alert(err));
  }

  function handleAvatarChange(value) {
    api.setUserAvatar(value)
      .then((res) => {
        setCurrentUser(res);
        closeAllPopups();
      })
      .catch((err) => window.alert(err));
  }

  function handlePlaceSubmit(card) {
    api.handleAddNewCard(card)
      .then((newCard) => {
        setCards([newCard, ...cards]);
        closeAllPopups();
      })
      .catch((err) => window.alert(err))
  }

  function onSuccess(title = 'Success!') {
    setToolTipTitle(title);
    setToolTipPicture('success');
    setIsInfoToolTipPopupOpen(true);
  }

  function onFail(title = 'Something went wrong. Please try again.') {
    setToolTipTitle(title);
    setToolTipPicture('error');
    setIsInfoToolTipPopupOpen(true);
  }

  useEffect(() => {
    if (localStorage.getItem('jwt')) {
      const jwt = localStorage.getItem('jwt');
      authentication.getData(jwt)
        .then((res) => {
          setLoggedIn(true);
          setEmail(res.email);
          
          // Check if the current path is /developer or /account
          if (window.location.pathname !== '/developer' && window.location.pathname !== '/account') {
            navigate('/', { replace: true });
          }
        })
        .catch(err => console.log(err));
    }
  }, [navigate]);

  function handleLogin(email, password) {
    authentication.authorize(email, password)
      .then(res => {
        localStorage.setItem('jwt', res.token)
        setLoggedIn(true);
        navigate('/', { replace: true })
      })
      .catch(err => {
        onFail(err);
        console.log(err);
      });
  }

  function handleRegistration(email, password) {
    authentication.register(email, password)
      .then(() => {
        navigate('/sign-in', { replace: true });
        onSuccess('Welcome!');
        handleLogin(email, password);
      })
      .catch(err => {
        onFail(err);
        console.log(err);
      });
  }

  function handleDeleteUser() {
    api.deleteUser()
      .then(() => {
        signOut()
        navigate('/sign-in', { replace: true });
        onSuccess("You're off the Internet!");
      })
      .catch(err => {
        onFail(err);
        console.log(err);
      });
  }

  function signOut() {
    localStorage.removeItem('jwt');
    setLoggedIn(false);
    navigate('/sign-in', { replace: true });
  }

  function handleKeyboard({ repeat, ctrlKey, key }) {
    if (key === 'Escape') closeAllPopups()
    if (repeat) return
    if (ctrlKey && key === '\\') setEditButtonsVisible(prev => !prev)
  }

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyboard)
    return () => document.removeEventListener('keydown', handleKeyboard)
  })

  useEffect(() => {
    function handleClick(e) {
      if (e.target.classList.contains('popup_opened')) {
        closeAllPopups()
      }
    }
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return (
    <CurrentUserContext.Provider value={currentUser}>
   
      <div className={loggedIn ? 'body page' : 'body_colorful'} >
      
        <Header
          email={email}
          signOut={signOut}
          loggedIn={loggedIn}
          smallPrintCounterSum={smallPrintCounterSum}
          bigPrintCounterSum={bigPrintCounterSum}
          digitalOptionCounterSum={digitalOptionCounterSum}
          onCheckoutClick={handleCheckoutClick}
        />

        {isLoading && <Preloader />}

        <Routes>
          
          <Route path='/' element={
            
            <ProtectedRoute
              loggedIn={loggedIn}
              onAvatarEdit={handleAvatarClick}
              onProfileEdit={handleProfileClick}
              onAdd={handleAddClick}
              onBulk={handleBulkClick}
              onDownloadOption={handleDownloadOptionCard}
              onYearbookOption={handleYearbookOptionCard}
              onHandleConfirmDeleteCard={handleConfirmDeleteCard}
              onSmallPrintClickUp={handleSmallPrintClickUp}
              onSmallPrintClickDown={handleSmallPrintClickDown}
              onBigPrintClickUp={handleBigPrintClickUp}
              onBigPrintClickDown={handleBigPrintClickDown}
              onImageClick={handleImageClick}
              cards={cards}
              editButtonsVisible={editButtonsVisible}
              element={Main}
            />
          }
          />
          <Route path='/account'
            element={
              
              <ProtectedRoute
                path='/account'
                loggedIn={loggedIn}
                handleDeleteUser={handleDeleteUser}
                element={Account}
                email={email}
              />
            }
          />
          <Route path='/developer'
            element={
              <ProtectedRoute
                path='/developer'
                loggedIn={loggedIn}
                element={Developer}
              />
            }
          />
          <Route path='/sign-in' element={<Login setEmail={setEmail} onLogin={handleLogin} />} />
          <Route path='/sign-up' element={<Register handleRegistration={handleRegistration} />} />
          <Route path='*' element={<Navigate to='/' replace />} />
        </Routes>

        {loggedIn && < Footer />}

        

        <EditAvatarPopup isOpen={isAvatarPopupOpen} onClose={closeAllPopups} onAvatarChange={handleAvatarChange} />
        <EditProfilePopup isOpen={isProfilePopupOpen} onClose={closeAllPopups} onUserUpdate={handleUserUpdate} />
        <AddPlacePopup isOpen={isPlacePopupOpen} onClose={closeAllPopups} onAdd={handlePlaceSubmit} />
        <AddBulkPopup isOpen={isBulkPopupOpen} onClose={closeAllPopups} onAdd={handlePlaceSubmit} />
        <ImagePopup isOpen={isImagePopupOpen} onClose={closeAllPopups} card={selectedCard} />
        <ConfirmationPopup isOpen={isConfirmationPupupOpen} onClose={closeAllPopups} card={selectedCard} onDeleteCard={handleDeleteCard} />
        <InfoToolTip isOpen={isInfoToolTipPopupOpen} onClose={closeAllPopups} title={toolTipTitle} toolTipPicture={toolTipPicture} />
        <CheckoutPopup isOpen={isCheckoutPopupOpen} onClose={closeAllPopups} isLoading={isLoading} setIsLoading={setIsLoading} smallPrintCounterSum={smallPrintCounterSum} bigPrintCounterSum={bigPrintCounterSum}
          digitalOptionCounterSum={digitalOptionCounterSum} yearbookOptionCounterSum={yearbookOptionCounterSum} email={email} cards={cards} onSuccess={onSuccess} onFail={onFail}></CheckoutPopup>
      </div>
    </CurrentUserContext.Provider>
  );
}

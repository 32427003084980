class Api {
  constructor({ siteUrl }) {
    this._siteUrl = siteUrl;
  }

  _checkResponse(response) {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(`Ошибка: ${response.status}`);
  }

  async getUserInfo() {
    const response = await fetch(`${this._siteUrl}users/me`, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem('jwt')}`
      }
    })
    return this._checkResponse(response);
  }

  async deleteUser() {
    const response = await fetch(`${this._siteUrl}users/me`, {
      method: 'DELETE',
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem('jwt')}`
      }
    })
    return this._checkResponse(response);
  }

  async setUserInfo(data) {
    const response = await fetch(`${this._siteUrl}users/me`, {
      method: 'PATCH',
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem('jwt')}`
      },
      credentials: this._credentails,
      body: JSON.stringify({
        name: data.name,
        about: data.about,
      }),
    });
    return this._checkResponse(response);
  }

  async setUserAvatar(data) {
    const response = await fetch(`${this._siteUrl}users/me/avatar`, {
      method: 'PATCH',
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem('jwt')}`
      },
      body: JSON.stringify({
        avatar: data.avatar,
      }),
    });
    return this._checkResponse(response);
  }

  async getServerCards() {
    const response = await fetch(`${this._siteUrl}cards`, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem('jwt')}`
      },
    });
    return this._checkResponse(response);
  }

  async handleAddNewCard(data) {
    const response = await fetch(`${this._siteUrl}cards`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem('jwt')}`
      },
      body: JSON.stringify({
        name: data.name,
        link: data.link,
        isClassPhoto: data.isClassPhoto,
      }),
    });
    return this._checkResponse(response);
  };

  async deleteCard(cardId) {
    const response = await fetch(`${this._siteUrl}cards/${cardId}`, {
      method: 'DELETE',
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem('jwt')}`
      },
    });
    return this._checkResponse(response);
  }

  async setSmallPrintCounterUp(cardId) {
    const response = await fetch(`${this._siteUrl}cards/${cardId}/smallPrintCounterUp`, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem('jwt')}`
      },
    });
    return this._checkResponse(response);
  }

  async setSmallPrintCounterDown(cardId) {
    const response = await fetch(`${this._siteUrl}cards/${cardId}/smallPrintCounterDown`, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem('jwt')}`
      },
    });
    return this._checkResponse(response);
  }

  async setBigPrintCounterUp(cardId) {
    const response = await fetch(`${this._siteUrl}cards/${cardId}/bigPrintCounterUp`, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem('jwt')}`
      },
    });
    return this._checkResponse(response);
  }

  async setBigPrintCounterDown(cardId) {
    const response = await fetch(`${this._siteUrl}cards/${cardId}/bigPrintCounterDown`, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem('jwt')}`
      },
    });
    return this._checkResponse(response);
  }

  async setCardDownloadOption(cardId) {
    const response = await fetch(`${this._siteUrl}cards/${cardId}/downloadOption`, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem('jwt')}`
      },
    });
    return this._checkResponse(response);
  }

  async setCardYearbookOption(cardId) {
    const response = await fetch(`${this._siteUrl}cards/${cardId}/yearbookOption`, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem('jwt')}`
      },
    });
    return this._checkResponse(response);
  }

  async removeCardDownloadOption(cardId) {
    const response = await fetch(`${this._siteUrl}cards/${cardId}/downloadOption`, {
      method: 'DELETE',
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem('jwt')}`
      },
    });
    return this._checkResponse(response);
  }

  async removeCardYearbookOption(cardId) {
    const response = await fetch(`${this._siteUrl}cards/${cardId}/yearbookOption`, {
      method: 'DELETE',
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem('jwt')}`
      },
    });
    return this._checkResponse(response);
  }

  async changeDownloadOptionCardState(cardId, isDownloadOption){
    if (isDownloadOption) {
      const response = await fetch(`${this._siteUrl}cards/${cardId}/downloadOption`, {
        method: 'DELETE',
        headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem('jwt')}`
      },
      credentials: 'include'
      });
      return this._checkResponse(response);
    } else {
      const response = await fetch(`${this._siteUrl}cards/${cardId}/downloadOption`, {
        method: 'PUT',
        headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem('jwt')}`
      },
      credentials: 'include'
      });
      return this._checkResponse(response);

    }
  }

  async changeYearbookOptionCardState(cardId, isYearbookOption){
    if (isYearbookOption) {
      const response = await fetch(`${this._siteUrl}cards/${cardId}/yearbookOption`, {
        method: 'DELETE',
        headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem('jwt')}`
      },
      credentials: 'include'
      });
      return this._checkResponse(response);
    } else {
      const response = await fetch(`${this._siteUrl}cards/${cardId}/yearbookOption`, {
        method: 'PUT',
        headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem('jwt')}`
      },
      credentials: 'include'
      });
      return this._checkResponse(response);

    }
  }

}
export const api = new Api({ siteUrl: 'https://api.myphotodelight.ru/' });
//export const api = new Api({ siteUrl: 'http://127.0.0.1:3001/' });
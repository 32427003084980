import React from 'react';
import errorPicture from '../images/cat-oops.png';

export default function ConfirmationPopup({ isOpen, onClose, onDeleteCard, card }) {
  function handleSubmit(e) {
    e.preventDefault();
    onDeleteCard(card);
    onClose()   
  }

  return (
    <div className={`popup ${isOpen ? "popup_opened" : ""}`}>
      <div className='popup__tooltip-container' >
        <img src={errorPicture} alt='warning' width='120px'></img>
        <h2 className='popup__tooltip-title'>Are you sure?</h2>
        <button type='button' className='popup__close-btn' onClick={onClose} />
        <p>This can't be undone</p>
        <input type="submit" onClick={handleSubmit} className='popup__submit-btn' value="Delete Forever" />
      </div>
    </div>
  )
}
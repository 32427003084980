import React from 'react';
import { Link } from 'react-router-dom'
import logo from '../images/logo.png'
import cart from '../images/cart.png'
import {digitalOptionCost, smallPrintCost, bigPrintCost} from '../utils/prices';

export default function Header({ signOut, loggedIn, smallPrintCounterSum, bigPrintCounterSum, digitalOptionCounterSum, onCheckoutClick }) {

  return (
    loggedIn ? (
      <header className='header'>
        <Link to='/'><img src={logo} className='header__logo' alt='Logo' /></Link>
        <div className='header__group'>
          <Link className='header__link' to='/account'>Account</Link>
          <Link className='header__link' onClick={onCheckoutClick}><img src={cart} alt='cart' width='30px' /> Checkout: {digitalOptionCounterSum * digitalOptionCost + smallPrintCounterSum * smallPrintCost + bigPrintCounterSum * bigPrintCost} RUB</Link>
          <Link className='header__link' onClick={signOut} to='/sign-in' >Sign out</Link>
        </div>
      </header>
    ) : (
      <header className='header header_noborder'></header>
    )
  )
}